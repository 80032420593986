import React from 'react';
import {Context} from "../index";
import {useLocation, useNavigate} from "react-router-dom";
import {SiteMethods} from "../methods/site.methods";
import {observer} from "mobx-react-lite";
import Button from "../ui/button/button";

const ButtonLocationComponent = ({filter}) => {
    const {stock} = React.useContext(Context);
    const [stocks, setStocks] = React.useState({});
    const location = useLocation();
    const navigate = useNavigate();
    const [loading, setLoading] = React.useState(false)
    const [defStock, setDefStock] = React.useState('');

    const loadingStock = React.useCallback(() => {
        (async () => {
            const [stocks, unitTypes] = await Promise.allSettled([
                SiteMethods.getStocks(), SiteMethods.getUnitTypes(localStorage.getItem('lang'))
            ])

            if (
                stocks.status === 'fulfilled' && unitTypes.status === 'fulfilled'
            ) {
                console.log('3333', stocks.value.data)
                setStocks(() => stocks.value.data);
                localStorage.setItem('stocks', JSON.stringify(stocks.value.data));
                stock.setStock(stocks.value.data);

                if (!localStorage.getItem('defStock') || !Object.values(stocks.value.data).find(i => i.id === Number(localStorage.getItem('defStock')))){
                    localStorage.setItem('defStock', Object.values(stocks.value.data)[0].id)
                }

                if (!unitTypes.value.data.find(i => i.id === Number(localStorage.getItem('defaultUnit')))) {
                    if (localStorage.getItem('lang') === 'ru'){
                        localStorage.setItem('defaultUnit', unitTypes.value.data[0].id)
                    } else {
                        localStorage.setItem('defaultUnit', unitTypes.value.data[0].resId)
                    }
                }
                setLoading(() => true);
            }
        })()
    }, []);

    React.useEffect(() => {
        if (loading && filter.length > 0 && localStorage.getItem('stocks')) {
            const sss = Object.values(JSON.parse(localStorage.getItem('stocks'))).find(st => st.name.toLowerCase() === filter.toLowerCase())
            if (sss) {
                localStorage.setItem('defStock', sss.id);
                localStorage.setItem('filter', filter);
                setDefStock(() => sss.id);
            }
        }
    }, [filter, loading])

    React.useEffect(() => {
        loadingStock();
    }, [location.pathname]);

    const changeDefaultStock = (id, name) => {
        localStorage.setItem('defStock', id);
        localStorage.setItem('filter', name);
        if (localStorage.getItem('lang') === 'lv') {
            navigate('/')
        } else {
            navigate(`/${localStorage.getItem('lang')}/`)
        }
        window.location.reload();
    }

    return (
        <div style={{flexWrap: 'wrap', marginTop: '2rem', marginBottom: '3rem', display: "flex", gap: '1rem'}}>
            {
                Object.values(stocks).map(stock =>
                    (
                        Number(localStorage.getItem('defStock')) === stock.id ||
                        Number(defStock) === stock.id
                    )
                    ?
                        stock.isActive &&
                        <>
                            <Button onClick={() => changeDefaultStock(stock.id, stock.name.toLowerCase())} variant={"fill"} key={stock.id}>{stock.address}</Button>

                            {
                                stock.coming_soon && <p className={'comingSoon'}>Coming Soon</p>
                            }

                        </>


                    :
                        stock.isActive &&
                        <Button onClick={() => changeDefaultStock(stock.id, stock.name.toLowerCase())} color={'white'} variant={"border"} key={stock.id}>{stock.address}</Button>

                )
            }

        </div>
    );
};

export default observer(ButtonLocationComponent);
