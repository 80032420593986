import React from 'react';
import Container from "../ui/container/container";
import Section from "../ui/section/section";
import Column from "../ui/column/column";
import Block from "../ui/block/block";
import ButtonBox from "../ui/buttonBox/buttonBox";
import Button from "../ui/button/button";
import Banner from "../ui/banner/banner";
import T from "./t.component";
import ButtonLocationComponent from "./buttonLocation.component";
import {observer} from "mobx-react-lite";
import {Context} from "../index";

const BannerComponent = ({filter}) => {
    const {stock} = React.useContext(Context)
    const [flt, setFlt] = React.useState(false);
    const [address, setAddress] = React.useState('https://ambar.kinnovis.com/1307458161?unitTypeCategoryId=1194937653&lang=');

    React.useEffect(() => {
        if (filter.length > 0 && stock.isStock) {
            const sss = Object.values(stock.isStock).find(st => st.name.toLowerCase() === filter.toLowerCase());
            if (sss) {
                setFlt(() => true);
                setAddress(() => sss.url)
            }
        }
    }, [filter, stock.isStock])

    React.useEffect(() => {
        if (!localStorage.getItem('filter')) {
            setFlt(() => false);
        } else {
            setFlt(() => true);
            const sss = Object.values(JSON.parse(localStorage.getItem('stocks'))).find(st => st.name.toLowerCase() === localStorage.getItem('filter').toLowerCase());
            if (sss) {
                setAddress(() => sss.url);
            }
        }
    })

    return (
        <>
            <Banner name={'banner'} video={true}>
                <Container height={'100'}>
                    <Section height={'100'} direction={'vertical'}>
                        <Section className={'reverse'}>
                            <Column className={'w100'} col={'12'}>
                                <ButtonLocationComponent filter={filter.length > 0 ? filter : ''}/>
                                <h1><T>Склады хранения личных вещей</T></h1>
                            </Column>
                            {/*<Column className={'w100 mb2'} col={'4'}>*/}
                            {/*    <LocationComponent />*/}
                            {/*</Column>*/}
                            {

                            }
                        </Section>
                        <Section  position={'end'}>
                            <Column className={'w100'} col={'6'}>
                                <Block>
                                    <p><T>Мы предлагаем круглосуточное видеонаблюдение, легкий доступ и гибкие условия аренды.
                                        Доверьте нам свои вещи и наслаждайтесь спокойствием, зная, что они в
                                        безопасности.</T></p>
                                </Block>
                                <ButtonBox>
                                    {
                                        flt
                                        ?
                                            <Button target={'_blank'} url={address + localStorage.getItem('lang')} variant={"fill_with_icon_link"} style={{textDecoration:'none'}}
                                                    icon={process.env.PUBLIC_URL + "/images/arrow_up.svg"}><T>Забронировать склад</T></Button>
                                        :
                                            <Button target={'_blank'} url={`${address}${localStorage.getItem('lang')}`} variant={"fill_with_icon_link"} style={{textDecoration:'none'}}
                                                    icon={process.env.PUBLIC_URL + "/images/arrow_up.svg"}><T>Забронировать склад</T></Button>
                                    }
                                    <Button target={'_blank'} url={localStorage.getItem('lang') === 'lv' ? 'https://business.ambar.lv/' : `https://business.ambar.lv/${localStorage.getItem('lang')}/`}  color={'white'} variant={"border_link"} icon={process.env.PUBLIC_URL + "/images/arrow_up.svg"}><T>Коммерческие Помещения</T></Button>
                                </ButtonBox>
                            </Column>
                        </Section>
                    </Section>
                </Container>
            </Banner>
        </>
    );
};

export default observer(BannerComponent);